import Http from '../../dbbqb'

export function show() {
    if (!document.getElementById('loading')) {
        let div = document.createElement('div')
        div.id = 'loading'
        div.style.position = 'absolute'
        div.style.inset = '0'
        div.style.background = 'rgba(0, 0, 0, 0.3)'
        div.style.zIndex = '9999'
        div.style.display = 'flex'
        div.style.justifyContent = 'center'
        div.style.alignItems = 'center'

        let img = document.createElement('img')
        img.style.width = '4rem'
        img.src = Http.resource('dbbqb/h5/image/loading.svg')
        div.appendChild(img)

        document.body.appendChild(div)
    }
}

export function hide() {
    // if (document.getElementById('loading')) {
    //     setTimeout(() => {
    if (document.getElementById('loading')) {
        document.body.removeChild(document.getElementById('loading'))
    }
    //     }, 500)
    // }
}