import React, { useImperativeHandle } from "react";
import * as Dbbqb from '../styleds/ArticleEditStyle';
import AddIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/HighlightOff';
import InputBase from '@mui/material/InputBase';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

const Input = styled(InputBase)({
    '& .MuiInputBase-input': {
        backgroundColor: '#F5F5F7',
        borderRadius: '0.5rem',
        padding: '0.5rem 0.75rem'
    }
});

const ShareDrawer = styled(Drawer)(() => ({
    '& .MuiDrawer-paper': {
        borderRadius: '0.5rem 0.5rem 0 0'
    }
}))

const DrawerUrl = React.forwardRef((props, ref) => {
    const [openUrlDrawer, setOpenUrlDrawer] = React.useState(false);
    const [urlTitle, setUrlTitle] = React.useState('');
    const [urlValue, setUrlValue] = React.useState('');
    const [urlText, setUrlText] = React.useState('');
    const [urlPreview, setUrlPreview] = React.useState(null);
    const [urlFile, setUrlFile] = React.useState(null);

    const inputFileEl = React.useRef(null)

    const urlTitleChange = e => setUrlTitle(e.target.value)
    const urlValueChange = e => setUrlValue(e.target.value)
    const urlTextChange = e => setUrlText(e.target.value)

    useImperativeHandle(ref, () => ({
        open: () => setOpenUrlDrawer(true),
        close: () => closeUrlDrawer()
    }))

    const closeUrlDrawer = () => {
        setOpenUrlDrawer(false)
        setUrlTitle('')
        setUrlValue('')
        setUrlText('')
        removeUrlPreview()
    }

    const addUrlSegment = () => {
        if (urlValue.indexOf('http://') !== 0 && urlValue.indexOf('https://') !== 0) {
            props.toast.show('链接格式错误')
            return
        }
        let segment = { type: 'URL', text: urlText, url: urlValue, title: urlTitle, preview: urlPreview }
        let file = {}
        file[urlPreview] = urlFile
        props.onChange(segment, file)
        closeUrlDrawer()
    }

    const openPreview = () => !Boolean(urlPreview) && inputFileEl.current.click()

    const inputFileChange = () => {
        let file = inputFileEl.current.files[0]
        if (file.size > 300 * 1024) {
            props.toast.show('预览图需小于300KB')
            removeUrlPreview()
            return
        }
        if (file.size < 10 * 1024) {
            props.toast.show('预览图需大于10KB')
            removeUrlPreview()
            return
        }
        let path = window.URL.createObjectURL(file)
        setUrlPreview(path)
        setUrlFile(file)
    }

    const removeUrlPreview = () => {
        setUrlPreview(null)
        setUrlFile(null)
    }

    return (
        <>
            <ShareDrawer anchor='bottom' open={openUrlDrawer} onClose={closeUrlDrawer}>
                <div style={{ margin: '1.25rem 0', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <span style={{ width: '4rem' }}>&nbsp;</span>
                    <span style={{ fontWeight: 700, fontSize: '1.125rem' }}>添加链接</span>
                    <Button color="secondary" size="small" onClick={addUrlSegment} disabled={!urlValue || !urlTitle}>完成</Button>
                </div>
                <div style={{ padding: '0 1.125rem 0.5rem' }}>
                    <div style={{ display: 'flex', marginBottom: '0.5rem' }}>
                        <Dbbqb.Preview onClick={openPreview}>
                            <AddIcon />
                            <span style={{ fontSize: '0.625rem' }}>预览图(可选)</span>
                            {
                                urlPreview &&
                                <Dbbqb.PreviewImage style={{ background: 'url(' + urlPreview + ') center center/cover' }} >
                                    <DeleteIcon onClick={removeUrlPreview} />
                                </Dbbqb.PreviewImage>
                            }
                        </Dbbqb.Preview>
                        <div style={{ width: 0, flexGrow: 1 }}>
                            <Input value={urlTitle} onChange={urlTitleChange} fullWidth placeholder="文本" style={{ marginBottom: '0.5rem' }} />
                            <Input value={urlValue} onChange={urlValueChange} fullWidth placeholder="链接" />
                        </div>
                    </div>
                    <Input value={urlText} onChange={urlTextChange} fullWidth multiline rows={3} placeholder="描述(可选)" />
                </div>
            </ShareDrawer>
            <input ref={inputFileEl} style={{ display: 'none' }} type="file" accept="image/*" onChange={inputFileChange} />
        </>
    )
})

export default DrawerUrl