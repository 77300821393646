import styled from '@emotion/styled';

export const Head = styled.div`
height: 4rem;
display: flex;
align-items: center;
justify-content: space-between;
`

export const SubmitButton = styled.button`
color: white;
background: linear-gradient(45deg,#FFBD00,#FF8800);
border: none;
border-radius: 1rem;
padding: 0.3rem 1rem;
`

export const Container = styled.div`
position: absolute;
inset: 7rem 0px 4.5rem;
overflow: scroll;
&::-webkit-scrollbar {
    display: none;
}
`

export const Title = styled.input`
border: none;
user-select: none;
outline: none;
font-weight: 700;
font-size: 1.25em;
color: #333;
padding-bottom: 1rem;
border-bottom: solid 1px #e5e5e5;
width: 100%;
`

export const Toolbar = styled.div`
position: absolute;
bottom: 0;
left: -1rem;
right: -1rem;
display: flex;
padding: 0.5rem 0 1rem;
align-items: center;
justify-content: space-around;
box-shadow: 0px -0.5px 0px #E5E5E5;
`

export const Tool = styled.div`
display: flex;
align-items: center;
background: #F5F5F5;
padding: 0.5rem 1rem;
border-radius: 2rem;
& img {
    width: 1.25rem;
};
& span {
    margin-left: 0.5rem;
}
`

export const Preview = styled.div`
height: 5.25rem;
width: 5.25rem;
color: #BAB8CC;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
border: dashed 1px #BAB8CC;
margin-right: 0.5rem;
border-radius: 0.5rem; 
position: relative;
`

export const PreviewImage = styled.div`
position: absolute;
inset: 0;
border-radius: 0.5rem; 
& :first-of-type {
    background: white;
    position: absolute;
    width: 1.5rem;
    height: 1.5rem;
    right: -0.5rem;
    top: -0.5rem;
    border-radius: 50%;    
}
`

export const SegmentImage = styled.div`
display: flex;
align-items: center;
justify-content: space-between;
margin-bottom: 0.5rem
`
