import * as loading from './pages/componts/Loading'

// const url = '//localhost:9000'
const url = '/api'

function queryParams(params) {
    let data = params || {}
    let query = ''
    Object.keys(data).forEach((k) => {
        query += (k + '=' + encodeURI(data[k]) + '&')
    })
    if (query.length > 0) {
        query = '?' + query.substring(0, query.length - 1)
    }
    return query
}

let throttleTimer;

export default {
    host(path, params) {
        return url + path + queryParams(params)
    },

    throttle(callback, time) {
        if (throttleTimer) return;
        throttleTimer = true;
        setTimeout(() => {
            callback();
            throttleTimer = false;
        }, time);
    },

    parseCookie(s, key) {
        s = s || ''
        if (s.length <= 0) {
            return ''
        }
        let ss = s.split(';')
        for (let i = 0; i < ss.length; i++) {
            let kv = ss[i].split('=')
            if (kv[0].trim() === key) {
                if (kv[1].trim().length > 0) {
                    return kv[1].trim()
                }
                return ''
            }
        }
        return ''
    },

    headers() {
        let headers = {
            'Accept': 'application/json', 'Content-Type': 'application/json', 'Web-Agent': 'app-web'
        }
        if (window.sessionStorage.getItem('token')) {
            headers.authentication = window.sessionStorage.getItem('token')
        }
        if (window.sessionStorage.getItem('source')) {
            headers.source = window.sessionStorage.getItem('source')
        }
        return headers
    },

    get(path, params, success, failure) {
        let fn = (req) => {
            let headers = {
                'Accept': 'application/json', 'Content-Type': 'application/json', 'Web-Agent': 'app-web'
            }
            headers[req[0]] = req[1]
            if (window.sessionStorage.getItem('token')) {
                headers.authentication = window.sessionStorage.getItem('token')
            }
            if (window.sessionStorage.getItem('source')) {
                headers.source = window.sessionStorage.getItem('source')
            }
            fetch(url + path + queryParams(params), {
                method: 'GET',
                headers: headers,
                credentials: 'include'
            }).then((res) => {
                res.text().then(s => {
                    try {
                        if (res.status >= 200 && res.status < 300) {
                            success && success(JSON.parse(s))
                        } else {
                            failure && failure(res.status)
                        }
                    } catch (e) {
                        failure && failure(400)
                    }
                })
            }).catch((err) => {
                failure && failure(500)
            })
        }
        if (window.encryption) {
            fn(['Client-Id', window.encryption()])
        } else {
            window.encryption = () => { return '' }
            fn(['Client-Id', window.encryption()])
        }
    },

    post(path, params, success, failure, headers) {
        let fn = (req) => {
            let h = {
                'Accept': 'application/json', 'Content-Type': 'application/json', 'Web-Agent': 'app-web'
            }
            h[req[0]] = req[1]
            if (window.sessionStorage.getItem('token')) {
                h.authentication = window.sessionStorage.getItem('token')
            }
            if (window.sessionStorage.getItem('source')) {
                h.source = window.sessionStorage.getItem('source')
            }
            fetch(url + path, {
                method: 'POST',
                headers: Object.assign(h, headers || {}),
                body: JSON.stringify(params),
                credentials: 'include'
            }).then((res) => {
                res.text().then(s => {
                    try {
                        if (res.status >= 200 && res.status < 300) {
                            success && success(JSON.parse(s))
                        } else {
                            failure && failure(res.status)
                        }
                    } catch (e) {
                        failure && failure(res.status)
                    }
                })
            }).catch((err) => {
                console.info(err)
            })
        }
        fn(['Client-Id', window.encryption()])
    },

    delete(path, success, failure) {
        let fn = (req) => {
            let headers = {
                'Accept': 'application/json', 'Content-Type': 'application/json', 'Web-Agent': 'app-web'
            }
            headers[req[0]] = req[1]
            if (window.sessionStorage.getItem('token')) {
                headers.authentication = window.sessionStorage.getItem('token')
            }
            if (window.sessionStorage.getItem('source')) {
                headers.source = window.sessionStorage.getItem('source')
            }
            fetch(url + path, {
                method: 'DELETE',
                headers: headers,
                credentials: 'include'
            }).then((res) => {
                res.text().then(s => {
                    try {
                        if (res.status >= 200 && res.status < 300) {
                            success && success(JSON.parse(s))
                        } else {
                            failure && failure(res.status)
                        }
                    } catch (e) {
                        failure && failure(400)
                    }
                })
            }).catch((err) => {
                failure && failure(500)
            })
        }
        fn(['Client-Id', window.encryption()])
    },

    shuffle(arr) {
        for (let i = arr.length; i; i--) {
            let j = Math.floor(Math.random() * i);
            [arr[i - 1], arr[j]] = [arr[j], arr[i - 1]];
        }
    },

    now() {
        let date = new Date();
        let y = date.getFullYear();
        let m = date.getMonth() + 1;
        m = m < 10 ? ('0' + m) : m;
        let d = date.getDate();
        d = d < 10 ? ('0' + d) : d;
        let h = date.getHours();
        h = h < 10 ? ('0' + h) : h;
        let minute = date.getMinutes();
        let second = date.getSeconds();
        minute = minute < 10 ? ('0' + minute) : minute;
        second = second < 10 ? ('0' + second) : second;
        return y + '-' + m + '-' + d + ' ' + h + ':' + minute + ':' + second;
    },

    path(image) {
        if (image.path && image.path.indexOf('http') === 0) {
            return image.path;
        }
        return '//image.dbbqb.com/' + image.path
    },

    resource(s) {
        return '//resource.dbbqb.com/' + (s || 'none.png')
    },

    initStorage() {
        let search = window.location.search
        let map = {}
        if (search.indexOf('?') >= 0) {
            search = search.substring(search.indexOf('?') + 1)
            search.split('&').map(arr => arr.split('=')).filter(arr => arr[1]).forEach(arr => {
                map[arr[0]] = arr[1]
                window.sessionStorage.setItem(arr[0], arr[1])
            })
        }
        const keys = Object.keys(sessionStorage)
        for (let i = 0; i < keys.length; i++) {
            if (!map[keys[i]]) {
                map[keys[i]] = sessionStorage.getItem(keys[i])
            }
        }
        return map
    },

    dbbqb(path) {
        return url + path
    },

    progress(show) {
        if (show) {
            loading.show()
        } else {
            loading.hide()
        }
    }
}