import styled from '@emotion/styled';

export const Container = styled.div`
position: relative;
background: #F4F4F4;
border-radius: 0.5rem;
padding: 0.5rem;
margin: 0 0.5rem;
& svg {
    background: white;
    position: absolute;
    width: 1.5rem;
    height: 1.5rem;
    right: -0.5rem;
    top: -0.5rem;
    color: #BAB8CC;
    border-radius: 50%;
}
`

export const Image = styled.div`
border-radius: 0.5rem;
width: 3rem;
height: 3rem;
`

export const Text = styled.div`
top: 0.5rem;
left: 4rem;
right: 0.5rem
`

export const Title = styled.div`
margin-bottom: 0.3rem;
color: #333;
font-size: 0.875rem;
text-overflow: ellipsis;
overflow: hidden;
white-space: nowrap
`

export const Content = styled.div`
font-size: 0.75rem;
color: #4F4F4F;
text-overflow: ellipsis;
overflow: hidden;
white-space: nowrap
`