import React from "react";
import Http from '../dbbqb';
import * as Dbbqb from './styleds/ArticleEditStyle';
import BackIcon from '@mui/icons-material/ArrowBackIos';
import { useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';

export default function WebView() {
    const [url, setUrl] = React.useState('');
    const [title, setTitle] = React.useState('');

    const navigate = useNavigate()

    React.useEffect(() => {
        const params = Http.initStorage()
        if (params.url) {
            setUrl(decodeURIComponent(params.url))
        }
        if (params.title) {
            setTitle(decodeURIComponent(params.title))
        }
    }, [])

    const back = () => navigate(-1)

    return (
        <>
            <Dbbqb.Tool style={{ padding: '0 1rem', boxShadow: '0 0 0.1rem 0.1rem #e0e0e0', justifyContent: 'initial' }}>
                <BackIcon style={{ padding: '1rem 0' }} onClick={back} />
                <Typography variant="h6" style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                    {title}
                </Typography>
            </Dbbqb.Tool>
            {
                url && <iframe src={url} style={{ border: 'none', marginTop: '0.1rem', width: '100%', height: 'calc(100% - 4rem)' }} />
            }
        </>
    );
}