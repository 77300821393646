import * as React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import * as Dbbqb from './styleds/ArticleDetailStyled';
import Http from '../dbbqb';
import ReplyList from './componts/ReplyList';
import SegmentUrl from './componts/SegmentUrl';
import Fab from '@mui/material/Fab';
import Drawer from '@mui/material/Drawer';
import { styled } from '@mui/material/styles';
import MoreIcon from '@mui/icons-material/MoreHoriz';
import EditIcon from '@mui/icons-material/Edit';
import { observer } from "mobx-react-lite";

export const ShowContext = React.createContext(null)

const ShareDrawer = styled(Drawer)(() => ({
    '& .MuiDrawer-paper': {
        borderRadius: '0.5rem 0.5rem 0 0'
    }
}))

// const Transition = React.forwardRef(function Transition(props, ref) {
//     return <Slide direction="left" ref={ref} {...props} />;
// });

function ArticleDetail({ toast }) {
    const params = useParams()
    const navigate = useNavigate()
    const inputEl = React.useRef(null)

    const [current, setCurrent] = React.useState(null)
    const [article, setArticle] = React.useState(null)
    const [user, setUser] = React.useState(null)
    const [msg, setMsg] = React.useState('')
    const [reply, setReply] = React.useState([])
    //const [preview, setPreview] = React.useState(false)
    const [refId, setRefId] = React.useState(null)
    const [total, setTotal] = React.useState(0)
    const [showShare, setShowShare] = React.useState(false)
    //const [isApp, setIsApp] = React.useState(false)
    const [queryParams, setQueryParams] = React.useState({})
    const [isAppStore, setIsAppStore] = React.useState()

    React.useEffect(() => {
        //const queryParams = Http.initStorage()
        setQueryParams(Http.initStorage())
        setIsAppStore(window.location.search.indexOf('app=true'))
        //setIsApp(queryParams.source)
        //setPreview(queryParams.preview)
        Http.get('/user', null, res => { setUser(res) })
        Http.get('/article/' + params.id + '/detail', null, res => {
            (res.thumbUps || []).forEach(t => {
                if (t.type === 'ARTICLE') {
                    res.isThumbUp = true
                } else {
                    (res.replies || []).filter(r => r.hashId === t.hashId).forEach(r => r.isThumbUp = true)
                }
            })
            replyData(res.replies || [], null)
            res.segments.forEach(s => {
                if (s.type === 'URL' && s.thumbnail) {
                    s.preview = Http.resource(s.thumbnail.path)
                }
            })
            setArticle(res)
        })
    }, [params.id])

    const getQueryParam = (key) => {
        return queryParams[key]
    }

    const keyPress = (e) => {
        if (e.key === 'Enter') {
            submit()
        }
    }

    const submitReply = (id, text, success) => {
        if (user === null) {
            if (window.dbbqb) {
                window.dbbqb.login()
            } else {
                toast.show('请先登录')
            }
            return
        }
        Http.post('/article/' + params.id + '/reply', {
            refId: id ? id : null, msg: text
        }, res => {
            replyData(res || [], id)
            success && success()
        }, code => {
            if (code === 401) {
                gotoLogin()
            }
        })
    }

    const submit = () => {
        submitReply(refId, msg, () => {
            setFocus()
        })
    }

    const changeMsg = e => {
        setMsg(e.target.value)
    }

    const setFocus = (id, nickname) => {
        const el = inputEl.current
        el.setAttribute('placeholder', nickname ? "回复" + nickname : "说点什么...")
        if (nickname) {
            el.focus()
            setRefId(id)
        } else {
            el.blur()
            setMsg('')
            setRefId(null)
        }
    }

    const replyData = (data, id) => {
        setTotal(data.length)
        let root = []
        let children = []
        for (let i = 0; i < data.length; i++) {
            if (!data[i].refId) {
                data[i].children = []
                root.push(data[i])
            } else {
                children.push(data[i])
            }
        }
        for (let i = 0; i < children.length; i++) {
            recursion(children[i], children)
        }
        for (let i = 0; i < root.length; i++) {
            for (let n = 0; n < children.length; n++) {
                if (root[i].hashId === children[n].refId) {
                    root[i].children.push(children[n])
                }
            }
        }
        setReply(root)
        root.filter(r => {
            if (r.hashId === id) {
                return true
            } else {
                return (r.children || []).filter(c => c.hashId === id).length > 0
            }
        }).forEach(r => setCurrent(r))
    }

    const recursion = (r, arr) => {
        for (let i = 0; i < arr.length; i++) {
            if (arr[i].hashId === r.refId) {
                r.refId = arr[i].refId
                if (!r.refName) {
                    r.refName = arr[i].user.nickname
                }
                recursion(r, arr)
            }
        }
    }

    const thumbUp = () => {
        Http.post('/article/' + params.id + '/thumbUp', {}, res => {
            article.thumbUp = article.thumbUp + (article.isThumbUp ? -1 : 1)
            article.isThumbUp = !article.isThumbUp
            setArticle({ ...article })
        }, code => {
            if (code === 401) {
                gotoLogin()
            }
        })
    }

    const gotoLogin = () => {
        if (window.dbbqb) {
            window.dbbqb.login()
        } else {
            toast.show('请先登录')
        }
    }

    const shareByQQ = () => {
        share('qq')
    }

    const shareByWechat = () => {
        share('wechat')
    }

    const shareByOther = () => {
        share('other')
    }

    const share = (channel) => {
        if (window.dbbqb) {
            let data = {
                type: 'url',
                channel: channel,
                title: article.title,
                data: 'https://' + window.location.host + window.location.pathname,
                image: 'https:' + Http.path(article.segments[0].images[0])
            }
            window.dbbqb.share(JSON.stringify(data))
        }
    }

    const openShowShare = () => {
        setShowShare(true)
    }

    const closeShowShare = () => {
        setShowShare(false)
    }

    const openPreview = (e, path) => {
        if (window.dbbqb) {
            const t = e.target
            window.dbbqb.imagePreview(path, t.naturalWidth + '', t.naturalHeight + '')
        }
    }

    const edit = () => {
        navigate("/app/article/edit.html?id=" + params.id + "&orgin=web");
    }

    const back = () => {
        if (getQueryParam('preview')) {
            navigate(-1)
        } else if (window.dbbqb) {
            window.dbbqb.back()
        } else {
            navigate(-1)
        }
    }

    return (
        <>
            <Dbbqb.Container>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} >
                    <div style={{ display: 'flex' }}>
                        {
                            (getQueryParam('preview') || getQueryParam('source')) && <div style={{ width: '3rem', height: '4rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClick={back}>
                                <img alt='' style={{ width: '0.5rem', height: '1rem' }} src={Http.resource('dbbqb/h5/image/icon_back.png')} />
                            </div>
                        }
                        {
                            article && <Dbbqb.ArticleUser style={(getQueryParam('preview') || getQueryParam('source')) ? {} : { marginLeft: '1.5rem' }}>
                                <Dbbqb.ArticleUserHeader style={{ background: 'url("' + Http.resource(article.user.header || 'default.png') + '") center', backgroundSize: '100%' }}>
                                </Dbbqb.ArticleUserHeader>
                                <p style={{ marginLeft: 10, fontSize: '1.2rem' }}>{article.user.nickname}</p>
                            </Dbbqb.ArticleUser>
                        }
                    </div>
                    {
                        article && article.status === 'RELEASE' && getQueryParam('source') &&
                        <div style={{ width: '4rem', height: '4rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClick={openShowShare}>
                            <img alt='' style={{ width: '1.5rem' }} src={Http.resource('dbbqb/h5/image/icon_share.png')} />
                        </div>
                    }
                    {
                        user && article && user.code === article.user.code &&
                        <Dbbqb.SubmitButton onClick={edit} style={{ marginRight: '1.2rem' }}>编辑</Dbbqb.SubmitButton>
                    }
                </div>
                {
                    article && <Dbbqb.Content>
                        <p style={{ fontSize: '1.5rem', fontWeight: 700, margin: '0 0 0.5rem', color: '#262628' }}>{article.title}</p>
                        <>{
                            article.segments.map((s, i) => (
                                <div key={'s_' + i} style={{ marginBottom: '1rem' }}>
                                    {
                                        s.type === 'TEXT' && <div style={{ fontSize: '1.2rem', color: '#333333' }}>{s.text}</div>
                                    }
                                    {
                                        s.type === 'IMAGE' && <>{
                                            article.type === 'GROUP' ? <Dbbqb.SegmentImage>{
                                                s.images.length === 3 && s.images.map((image, n) => (
                                                    <img alt='' key={'i_' + n} style={{ width: '32%', borderRadius: '0.5rem' }} src={Http.path(image)}
                                                        onClick={(e) => { openPreview(e, 'https:' + Http.path(image)) }} />
                                                ))
                                            }</Dbbqb.SegmentImage> : <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly' }}>{
                                                s.images.map((image, n) => (
                                                    <img alt='' key={'i_' + n} style={{ width: Math.floor((100 - s.images.length) / s.images.length) + '%', borderRadius: '0.5rem' }} src={Http.resource(image.path)}
                                                        onClick={(e) => { openPreview(e, 'https:' + Http.resource(image.path)) }} />
                                                ))
                                            }</div>
                                        }</>
                                    }
                                    {
                                        s.type === 'URL' && <SegmentUrl segment={s} href={true} />
                                    }
                                </div>
                            ))
                        }</>
                        {
                            isAppStore <= 0 && <>
                                <div style={{ fontSize: '0.875rem', margin: 0, color: '#B2B2B2' }}>{article.createDate.split(' ')[0]}</div>
                                <hr style={{ height: 1, background: '#e5e5e5', border: 'none', margin: '0.8rem 0' }} />
                                <p style={{ fontSize: '0.9rem', marginBottom: 10 }}>{total}条评论</p>
                                {
                                    total <= 0 && <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                        <img alt='' src={Http.resource('/dbbqb/h5/image/icon_empty.png')}
                                            style={{ width: '8rem', margin: '2rem 0 1rem' }} />
                                        <span style={{ color: '#bbb', fontSize: '0.8rem', marginBottom: '2rem' }}>留下您的想法</span>
                                    </div>
                                }
                                <ShowContext.Provider value={{ current, setCurrent }}>
                                    <ReplyList article={article} reply={reply} setFocus={setFocus} submitReply={submitReply} gotoLogin={gotoLogin} />
                                </ShowContext.Provider>
                            </>
                        }
                    </Dbbqb.Content>
                }
                {
                    isAppStore <= 0 &&
                    <Dbbqb.Foot>
                        <div style={{ display: 'flex', alignItems: 'center', flexGrow: 1, position: 'relative' }}>
                            <Dbbqb.Input ref={inputEl} placeholder="说点什么..." value={msg} onChange={changeMsg} onKeyDown={keyPress} />
                            <Dbbqb.Submit onClick={submit}>发布</Dbbqb.Submit>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <img alt='' style={{ width: '1.5rem', height: '1.5rem', margin: '0 0 0 1rem' }}
                                src={Http.resource('dbbqb/h5/image/icon_reply.png')}
                                onClick={() => inputEl.current.focus()} />
                            <span style={{ margin: '0 0.5rem' }}>{total}</span>
                            <img alt='' style={{ width: '1.5rem', height: '1.5rem', margin: '0 0.5rem' }} onClick={thumbUp}
                                src={(article && article.isThumbUp) ?
                                    Http.resource('dbbqb/h5/image/icon_thumbs_up_1.png') :
                                    Http.resource('dbbqb/h5/image/icon_thumbs_up_0.png')
                                } />
                            <span>{article ? article.thumbUp : 0}</span>
                        </div>
                    </Dbbqb.Foot>
                }
            </Dbbqb.Container >
            <ShareDrawer anchor='bottom' open={showShare} onClose={closeShowShare}>
                <p style={{ padding: '1rem 1rem 0' }}>分享到</p>
                <div style={{ display: 'flex', padding: '1rem', fontSize: '0.8rem', color: '#333333' }}>
                    <Dbbqb.ShareItem onClick={shareByQQ}>
                        <img alt='' src={Http.resource('/dbbqb/h5/image/icon_qq.png')} />
                        <span>QQ</span>
                    </Dbbqb.ShareItem>
                    <Dbbqb.ShareItem onClick={shareByWechat}>
                        <img alt='' src={Http.resource('/dbbqb/h5/image/icon_wechat.png')} />
                        <span>微信</span>
                    </Dbbqb.ShareItem>
                    <Dbbqb.ShareItem onClick={shareByOther}>
                        <MoreIcon style={{ padding: '0.5rem', width: '2rem', height: '2rem', boxShadow: '0 0 0.1rem 0 #bbb', borderRadius: '0.6rem' }} />
                        <span>更多</span>
                    </Dbbqb.ShareItem>
                </div>
            </ShareDrawer>
        </>
    )
}

export default observer(ArticleDetail)