import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { observer } from "mobx-react-lite";
import ArticleList from "./pages/ArticleList"
import ArticleDetail from "./pages/ArticleDetail"
import ArticleEdit from "./pages/ArticleEdit"
import WebView from "./pages/WebView"
import UserArticleList from "./pages/user/ArticleList"
import MuiSnackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import MsgStore from "./stores/MsgStore"
import { styled } from '@mui/material/styles';

const msgStore = new MsgStore()

const Snackbar = styled(MuiSnackbar)({
    '& .MuiPaper-root': {
        boxShadow: '0 0 1rem 0 #e0e0e0'
    }
});

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} {...props} />;
});

const router = createBrowserRouter([
    { path: "/", element: <div>Startup</div> },
    { path: "/app", element: <ArticleList toast={msgStore} /> },
    { path: "/app/article.html", element: <ArticleList toast={msgStore} /> },
    { path: "/app/article/:id/detail.html", element: <ArticleDetail toast={msgStore} /> },
    { path: "/app/article/edit.html", element: <ArticleEdit toast={msgStore} /> },
    { path: "/app/user/article.html", element: <UserArticleList toast={msgStore} /> },
    { path: "/app/web/view.html", element: <WebView /> },
]);

function App() {
    const closeAlertMsg = () => msgStore.hide()

    return (
        <>
            <RouterProvider router={router} />
            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                open={Boolean(msgStore.msg)}
                autoHideDuration={3000}
                onClose={closeAlertMsg}
                style={{ bottom: '8rem' }}>
                <Alert icon={false} severity="warning" >{msgStore.msg}</Alert>
            </Snackbar>
        </>
    );
}

export default observer(App);
