import styled from '@emotion/styled';

export const Header = styled.div`
position: fixed;
top: 0px;
z-index: 99;
left: 0px;
right: 0px;
background: white;
padding: 1.2rem 1.2rem 1rem;
color: rgb(38, 38, 40);
font-size: 1.6rem;
display: flex;
align-items: center;
`

export const Cover = styled.img`
display: block;
border-radius: 0.5rem;
box-shadow: 0 0 0.1rem 0 #bbb;
width: 100%;
`

export const Title = styled.p`
font-size: 1rem;
margin-top: 1rem;
margin-bottom: 0.5rem;
font-weight: 700;
`

export const User = styled.div`
font-size: 0.9rem;
margin-bottom: 0.9rem;
display: flex;
align-items: center;
`

export const UserHeader = styled.div`
display: block;
width: 1.2rem;
height: 1.2rem;
border-radius: 50%;
margin-right: 0.2rem;
`

export const Tool = styled.div`
display: flex;
align-items: center;
padding: 0.5rem 1rem;
& img {
    width: 1.25rem;
};
& span {
    margin-left: 0.5rem;
}
`