
import * as Dbbqb from '../styleds/SegmentUrlStyled';
import { useNavigate } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/HighlightOff';

export default function SegmentUrl(props) {
    const navigate = useNavigate()

    const click = e => {
        if (props.href) {
            if (window.dbbqb) {
                window.dbbqb.ad(props.segment.url, props.segment.title)
            } else {
                navigate("/app/web/view.html?url=" + encodeURIComponent(props.segment.url) + '&title=' + encodeURIComponent(props.segment.title));
            }
        }
    }

    return (
        <Dbbqb.Container onClick={click}>
            {
                props.segment.preview && <Dbbqb.Image style={{ background: 'url(' + props.segment.preview + ') center center/cover' }} ></Dbbqb.Image>
            }
            <Dbbqb.Text style={{ position: props.segment.preview ? 'absolute' : 'initial' }}>
                <Dbbqb.Title>{props.segment.title}</Dbbqb.Title>
                <Dbbqb.Content>{props.segment.text || props.segment.url}</Dbbqb.Content>
            </Dbbqb.Text>
            {
                props.onClose && <DeleteIcon onClick={props.onClose} />
            }
        </Dbbqb.Container>
    )
}