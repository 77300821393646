import React from 'react';
import * as Dbbqb from '../styleds/ReplyListStyled';
import Http from '../../dbbqb';
import Drawer from '@mui/material/Drawer';
import { styled } from '@mui/material/styles';
import { ShowContext } from '../ArticleDetail'

const CustomizedDrawer = styled(Drawer)(() => ({
    '& .MuiDrawer-paper': {
        borderRadius: '0.5rem 0.5rem 0 0'
    }
}))

function ReplyDrawer(props) {
    const { current, setCurrent } = React.useContext(ShowContext)
    const inputEl = React.useRef(null)

    const [msg, setMsg] = React.useState('')
    const [refId, setRefId] = React.useState(null)

    const close = () => {
        setCurrent(null)
    }

    const setFocus = (id, nickname) => {
        const el = inputEl.current
        el.setAttribute('placeholder', '回复' + nickname)
        el.focus()
        setRefId(id)
    }

    const keyPress = (e) => {
        if (e.key === 'Enter') {
            submitReply()
        }
    }

    const blur = () => {
        inputEl.current.setAttribute('placeholder', '回复' + current.user.nickname)
    }

    const submitReply = () => {
        props.submitReply && props.submitReply(refId || current.hashId, msg, () => {
            const el = inputEl.current
            el.setAttribute('placeholder', '回复' + current.user.nickname)
            setRefId(current.hashId)
            setMsg('')
        })
    }

    const changeMsg = e => {
        setMsg(e.target.value)
    }

    return (
        <CustomizedDrawer anchor='bottom' open={current !== null} onClose={close}>{
            current && <div style={{ height: Math.ceil(document.body.clientHeight * 0.7), position: 'relative' }}>
                <Dbbqb.Content>
                    <ReplyDetail r={current} article={props.article} setFocus={setFocus} gotoLogin={props.gotoLogin} />
                    {
                        current.children &&
                        <div style={{ marginBottom: 10, fontSize: '1rem' }}>回复&nbsp;{current.children.length}</div>
                    }
                    {
                        current.children && current.children.map((r, i) => (
                            <ReplyDetail key={'r' + r.hashId} r={r} article={props.article} setFocus={setFocus} gotoLogin={props.gotoLogin} />
                        ))
                    }
                </Dbbqb.Content>
                <Dbbqb.InputDiv>
                    <Dbbqb.Input ref={inputEl} placeholder={'回复' + current.user.nickname} value={msg} onChange={changeMsg} onKeyDown={keyPress} onBlur={blur} />
                    <Dbbqb.Enter onClick={submitReply}>发布</Dbbqb.Enter>
                </Dbbqb.InputDiv>
            </div>
        }</CustomizedDrawer>
    )
}

function ReplyDetail(props) {
    const [r, setR] = React.useState(null)
    const [author, setAuthor] = React.useState(null)

    const { current, setCurrent } = React.useContext(ShowContext)

    React.useEffect(() => {
        setR(props.r)
        setAuthor(props.article.user)
    }, [])

    const openDrawer = () => {
        setCurrent(r)
    }

    const setFocus = () => {
        props.setFocus && props.setFocus(r.hashId, r.user.nickname)
    }

    const thumbUp = (id) => {
        Http.post('/article/' + props.article.hashId + '/reply/' + id + '/thumbUp', {}, res => {
            r.thumbUp = r.thumbUp + (r.isThumbUp ? -1 : 1)
            r.isThumbUp = !r.isThumbUp
            setR({ ...r })
        }, code => {
            if (code === 401) {
                props.gotoLogin && props.gotoLogin()
            }
        })
    }

    return (
        <>
            {
                r && <Dbbqb.Container>
                    <Dbbqb.UserHeader style={{ backgroundImage: 'url("' + Http.resource(r.user.header || '/default.png') + '")' }}></Dbbqb.UserHeader>
                    <div style={{ flexGrow: 1 }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div style={{ fontSize: '1rem' }}>
                                {r.user.nickname}
                            </div>
                            {r.user.hashId === author.hashId && <Dbbqb.Author>作者</Dbbqb.Author>}
                        </div>
                        <div style={{ marginBottom: '0.5rem' }}>
                            <span style={{ color: '#BAB8CC', fontSize: '0.9rem', flexGrow: 1 }}>{r.createDate.split(' ')[0]}</span>
                        </div>
                        <div style={{ fontSize: '1rem', marginBottom: 10 }} >
                            {r.refName && <span style={{ color: '#333333' }}>回复</span>}
                            {r.refName && <span style={{ color: '#333333' }}>{r.refName}：</span>}
                            {r.text}
                        </div>
                        {
                            props.position === 1 && r.children.length > 0 && <Dbbqb.ReplyChild>
                                {
                                    (r.children.length > 2 ? r.children.slice(0, 2) : r.children).map(c => (<div key={'c_' + c.hashId} style={{ marginBottom: '0.3rem' }}>
                                        <span>{c.user.nickname}：{c.text}</span>
                                    </div>))
                                }
                                <span style={{ color: '#BAB8CC' }} onClick={openDrawer}>查看全部{r.children.length}条评论&gt;</span>
                            </Dbbqb.ReplyChild>
                        }
                    </div>
                </Dbbqb.Container>
            }
            <Dbbqb.Toolbar>
                <img alt='' style={{ marginRight: '1rem' }} src={Http.resource('dbbqb/h5/image/icon_reply.png')} onClick={setFocus} />
                <img alt='' onClick={() => thumbUp(r.hashId)}
                    src={(r && r.isThumbUp) ?
                        Http.resource('dbbqb/h5/image/icon_thumbs_up_1.png') :
                        Http.resource('dbbqb/h5/image/icon_thumbs_up_0.png')
                    } />
                <span style={{ margin: '0 0.3rem' }}>{r ? r.thumbUp : 0}</span>
            </Dbbqb.Toolbar>
        </>
    )
}

export default function ReplyList(props) {
    return (
        <>
            {
                props.reply.map(r => (
                    <ReplyDetail key={'r_' + r.hashId} article={props.article} r={r} position={1} setFocus={props.setFocus} gotoLogin={props.gotoLogin} />
                ))
            }
            <ReplyDrawer article={props.article} submitReply={props.submitReply} gotoLogin={props.gotoLogin} />
        </>
    )
}