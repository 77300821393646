import React from "react";
import { MasonryInfiniteGrid } from "@egjs/react-infinitegrid";
import * as Dbbqb from './styleds/ArticleListStyled';
import Http from '../dbbqb';
import { useNavigate } from 'react-router-dom';
import { observer } from "mobx-react-lite";

let loading = false

function ArticleList({ toast }) {
    const [list, setList] = React.useState([]);
    const [over, setOver] = React.useState(false);

    const navigate = useNavigate();

    React.useEffect(() => {
        Http.initStorage()
    }, []);

    const setLoading = bool => loading = bool

    const query = (fn) => {
        setLoading(true)
        Http.get('/article', { status: 'RELEASE', start: list.length }, res => {
            if ((res.data || []).length > 0) {
                let temp = []
                res.data.filter(a => a.cover)
                    .filter(a => list.filter(b => b.hashId === a.hashId).length <= 0)
                    .forEach(a => {
                        if (a.title.lastIndexOf("表情包") === a.title.length - 3) {
                            a.title = a.title.substring(0, a.title.length - 3)
                        }
                        if (a.title.lastIndexOf("图片") === a.title.length - 2) {
                            a.title = a.title.substring(0, a.title.length - 2)
                        }
                        temp.push(a)
                    })
                if (temp.length > 0) {
                    setList(a => [...a, ...temp])
                    fn && fn()
                }
            } else {
                setOver(true)
            }
            setLoading(false)
        }, err => setLoading(false))
    }

    const load = e => {
        if (!loading && !over) {
            e.wait()
            query(() => e.ready())
        }
    }

    const goto = (id) => {
        if (window.dbbqb) {
            window.dbbqb.goto("https://www.ddoult.com/app/article/" + id + "/detail.html")
        } else {
            navigate("/app/article/" + id + "/detail.html");
        }
    }

    const edit = () => {
        if (window.dbbqb) {
            window.dbbqb.goto("https://www.ddoult.com/app/article/edit.html")
        } else {
            navigate("/app/article/edit.html");
        }
    }

    return (
        <>
            <Dbbqb.Header>
                <span style={{ flexGrow: 1 }}>逗逗社区</span>
                {/* <IconButton onClick={edit}><PlusIcon /></IconButton> */}
            </Dbbqb.Header>
            <MasonryInfiniteGrid style={{ position: 'absolute', margin: '0 1.2rem 0', inset: 0, top: '4.5rem' }} onRequestAppend={load}>
                {
                    list.map(item => (
                        <div key={item.hashId} style={{ width: 'calc(50% - 10px)' }}>
                            {
                                item.type === 'ARTICLE' ?
                                    <Dbbqb.Cover src={Http.resource(item.cover.path)} onClick={() => goto(item.hashId)} /> :
                                    <Dbbqb.Cover src={Http.path(item.cover)} onClick={() => goto(item.hashId)} />
                            }
                            <Dbbqb.Title>{item.title}</Dbbqb.Title>
                            <Dbbqb.User>
                                <Dbbqb.UserHeader style={{ background: 'url("' + Http.resource(item.user.header) + '") center', backgroundSize: '100%' }}></Dbbqb.UserHeader>
                                <p>{item.user.nickname}</p>
                            </Dbbqb.User>
                        </div>
                    ))
                }
            </MasonryInfiniteGrid>
        </>
    )
}

export default observer(ArticleList)