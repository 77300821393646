
import React, { useImperativeHandle } from "react";
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';

const Input = styled(TextField)({
    '& .MuiInput-root': {
        '&:before': {
            border: 'none',
        }
    }
});

const SegmentText = React.forwardRef((props, ref) => {

    const [text, setText] = React.useState('');

    React.useEffect(() => {
        setText(props.segment.text || '')
    }, [])

    const change = e => {
        setText(e.target.value)
        props.onChange && props.onChange(e.target.value)
    }

    const keyDown = e => {
        if (e.code === 'Backspace' && text.length <= 0) {
            props.onClose && props.onClose()
        }
    }

    return (
        <>
            <Input value={text}
                variant="standard"
                fullWidth={true}
                multiline={true}
                maxRows={10}
                color="warning"
                onChange={change}
                onKeyDown={keyDown}
                placeholder="输入文字..." />
        </>
    )
})

export default SegmentText