import React from "react";
import { useNavigate } from 'react-router-dom';
import * as Dbbqb from './styleds/ArticleEditStyle';
import Http from '../dbbqb';
import BackIcon from '@mui/icons-material/ArrowBackIos';
import SegmentText from './componts/SegmentText';
import SegmentImage from './componts/SegmentImage';
import SegmentUrl from './componts/SegmentUrl';
import DrawerUrl from './componts/DrawerUrl';
import { observer } from "mobx-react-lite";

function ArticleEdit({ toast }) {
    const [articleId, setArticleId] = React.useState(null);
    const [title, setTitle] = React.useState('');
    const [type, setType] = React.useState('ARTICLE');
    const [segments, setSegments] = React.useState([]);
    const [files, setFiles] = React.useState({});
    const [orgin, setOrgin] = React.useState(null);

    const navigate = useNavigate();

    const drawerUrlRef = React.useRef();

    React.useEffect(() => {
        const params = Http.initStorage()
        setOrgin(params.orgin)
        if (params.id) {
            setArticleId(params.id)
            loadArticle(params.id)
        }
    }, [])

    const loadArticle = (id) => {
        Http.get('/article/' + id + '/detail', null, res => {
            setTitle(res.title)
            setType(res.type)
            setSegments(res.segments.map(s => {
                if (s.type === 'URL' && s.thumbnail) {
                    s.preview = Http.resource(s.thumbnail.path)
                    return s
                } else if (s.type === 'IMAGE') {
                    s.ref = React.createRef()
                    s.images = s.images.map(a => a.path)
                    return s
                } else {
                    return s
                }
            }))
        })
    }

    const addTextSegment = () => setSegments(s => [...s, { type: 'TEXT' }])

    const addImageSegment = () => setSegments(s => [...s, { type: 'IMAGE', ref: React.createRef() }])

    const addSegment = (segment, file) => {
        setSegments(s => [...s, segment])
        file && setFiles(s => Object.assign(s, file))
    }

    const delSegment = (index) => {
        let temp = []
        for (let i = 0; i < segments.length; i++) {
            if (i === index) {
                continue
            }
            temp.push(segments[i])
        }
        setSegments(temp)
    }

    const segmentChange = (s, index) => {
        let temp = []
        for (let i = 0; i < segments.length; i++) {
            if (i === index) {
                if (segments[i].type === 'TEXT') {
                    segments[i].text = s
                } else if (segments[i].type === 'IMAGE') {
                    segments[i].images = s.filter(a => a)
                }
            }
            temp.push(segments[i])
        }
        setSegments(temp)
    }

    const save = (status) => {
        if (title.length <= 0) {
            toast.show('请输入标题')
            return
        }
        if (segments.length <= 0) {
            toast.show('请输入一点内容')
            return
        }
        if (segments.filter(v => v.type === 'IMAGE').length <= 0) {
            toast.show('请至少添加一张图片')
            return
        }
        Http.progress(true)
        let form = new FormData()
        let method = 'post'
        if (articleId) {
            form.append("articleId", articleId)
            method = 'put'
        }
        form.append("title", title)
        form.append("type", type)
        form.append("status", status)
        let map = files
        segments.forEach((s, i) => {
            s.sort = i
            if (s.type === 'IMAGE') {
                Object.assign(map, s.ref.current.data())
            }
        })
        let keys = Object.keys(map)
        keys.forEach(key => form.append(key, map[key]))
        form.append("segments", JSON.stringify(segments))
        const token = window.sessionStorage.getItem('token')
        fetch(Http.dbbqb('/article'), { method: method, headers: { 'Authentication': token }, body: form }).then((res) => res.json()).then(json => {
            if (json.error) {
                toast.show(json.error)
            } else {
                toast.show(status === 'SUBMIT' ? '已提交审核~' : '保存成功～')
                setArticleId(json.id)
                loadArticle(json.id)
            }
            Http.progress(false)
        }).catch(err => {
            toast.show('出错啦～')
            Http.progress(false)
        })
    }

    const back = () => {
        if (orgin != 'web' && window.dbbqb) {
            window.dbbqb.back()
        } else {
            navigate(-1)
        }
    }

    return (
        <div style={{ margin: '0 1rem', position: 'absolute', inset: 0 }} >
            <Dbbqb.Head>
                <BackIcon style={{ padding: '1rem 1rem 1rem 0' }} onClick={back} />
                <div style={{ flexGrow: 1 }}></div>
                <Dbbqb.SubmitButton onClick={() => save('NEW')} style={{ marginRight: '1rem', background: 'linear-gradient(45deg,#c0c0c0,#a0a0a0)' }}>保存</Dbbqb.SubmitButton>
                <Dbbqb.SubmitButton onClick={() => save('SUBMIT')}>发布</Dbbqb.SubmitButton>
            </Dbbqb.Head>
            <Dbbqb.Title value={title} onChange={e => setTitle(e.target.value)} placeholder="请输入标题" />
            {
                segments.length === 0 && <div style={{ color: '#999', marginTop: '0.8rem' }}>请点击下方按钮，添加内容~</div>
            }
            <Dbbqb.Container>
                <>{
                    segments.map((s, i) => (<div id={'s_' + i} key={'s_' + i} style={{ marginBottom: '0.5rem' }}><>
                        {
                            s.type === 'TEXT' &&
                            <SegmentText segment={s} onChange={s => segmentChange(s, i)} onClose={() => delSegment(i)} />
                        }
                        {
                            s.type === 'IMAGE' && <>{type === 'ARTICLE' ?
                                <SegmentImage segment={s} onChange={s => segmentChange(s, i)} onClose={() => delSegment(i)} ref={s.ref} toast={toast} /> :
                                <Dbbqb.SegmentImage>{s.images.length === 3 && s.images.map((image, n) => (
                                    <img alt='' key={'i_' + n} style={{ width: '32%', borderRadius: '0.5rem' }} src={Http.path(image)} />
                                ))}</Dbbqb.SegmentImage>
                            }</>
                        }
                        {
                            s.type === 'URL' &&
                            <SegmentUrl segment={s} onClose={() => delSegment(i)} />
                        }
                    </></div>))
                }</>
            </Dbbqb.Container>
            <Dbbqb.Toolbar>
                <Dbbqb.Tool onClick={addTextSegment}>
                    <img src={Http.resource('dbbqb/h5/image/icon_word.png')} style={{ width: '1rem' }} alt="" /><span>文本</span>
                </Dbbqb.Tool>
                {
                    type !== 'GROUP' &&
                    <Dbbqb.Tool onClick={addImageSegment}>
                        <img src={Http.resource('dbbqb/h5/image/icon_image_pick.png')} alt="" /><span>图片</span>
                    </Dbbqb.Tool>
                }
                <Dbbqb.Tool onClick={() => drawerUrlRef.current.open()}>
                    <img src={Http.resource('dbbqb/h5/image/icon_href.png')} alt="" /><span>链接</span>
                </Dbbqb.Tool>
            </Dbbqb.Toolbar>
            <DrawerUrl ref={drawerUrlRef} toast={toast} onChange={addSegment} />
        </div >
    )
}

export default observer(ArticleEdit)