import { makeAutoObservable } from "mobx"

class MsgStore {
    msg = null
    constructor() {
        makeAutoObservable(this)
    }

    show(s) {
        if (window.dbbqb && window.dbbqb.toast) {
            this.hide()
            window.dbbqb.toast(s)
        } else {
            this.msg = s
        }
    }

    hide() {
        this.msg = null
    }
}

export default MsgStore