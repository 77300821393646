import styled from '@emotion/styled';

export const Container = styled.div`
margin: 1rem 0;
display: flex;
justify-content: space-evenly;
`

export const Image = styled.div`
display: flex;
justify-content: center;
align-items: center;
width: calc(30vw - 1rem);
height: calc(30vw - 1rem);
border: 0.1rem gray dashed;
border-radius: 0.5rem;
position: relative;
background-size: cover;
background-position: center;
& svg {
    width: 1.5rem;
    height: 1.5rem;
    color: #BAB8CC;
    padding: 1rem;
};
& .del {
    background: white;
    position: absolute;
    right: -0.8rem;
    top: -0.8rem;
    border-radius: 50%;
    padding: 0;
}
`