import styled from '@emotion/styled';

export const Container = styled.div`
display: flex;
margin-bottom: 1.2rem; 
`

export const UserHeader = styled.div`
display: block;
width: 2rem;
height: 2rem;
margin-right: 0.5rem;
background-size: 100%;
background-position: center;
border-radius: 50%;
box-shadow: 0 0 0.1rem 0 #bbb;
`

export const Author = styled.div`
padding: 0 0.3rem;
font-size: 0.8rem;
border-radius: 0.3rem;
color: #ABA8C6;
background: #F6F6FC;
margin-left: 0.3rem
`

export const ReplyChild = styled.div`
font-size: 0.9rem;
background: #f5f5f5;
padding: 1rem;
border-radius: 0.3rem;
`

export const Toolbar = styled.div`
display: flex;
padding-bottom: 1.2rem;
justify-content: flex-end;
align-items: center;
margin-bottom: 1.2rem;
border-bottom: solid 1px #e5e5e5;
font-size: 0.9rem;
&:last-child {
    borderBottom: none
}
& img {
    width: 1.2rem;
    height: 1.2rem
}
`

export const Content = styled.div`
position: absolute;
overflow: scroll;
top: 1.5rem;
left: 1.5rem;
right: 1.5rem;
bottom: 3.5rem;
&::-webkit-scrollbar { display: none }
`

export const InputDiv = styled.div`
box-shadow: 0 -0.1rem 0.1rem #f5f5f5;
position: absolute;
bottom: 0;
height: 3.5rem;
display: flex;
width: 100%;
align-items: center;
`

export const Input = styled.input`
border: none;
outline: none;
background: #f5f5f5;
border-radius: 2rem;
padding: 0.6rem 0.8rem;
width: 100%;
margin: 0 1rem;
`

export const Enter = styled.span`
color: #BAB8CC;
position: absolute;
top: 1rem;
right: 2.5rem;
z-index: 1;
`