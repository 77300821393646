import React from "react";
import { MasonryInfiniteGrid } from "@egjs/react-infinitegrid";
import * as Dbbqb from '../styleds/ArticleListStyled';
import Http from '../../dbbqb';
import { useNavigate } from 'react-router-dom';
import BackIcon from '@mui/icons-material/ArrowBackIos';
import { observer } from "mobx-react-lite";

function ArticleList({ toast }) {
    const [list, setList] = React.useState([]);

    const navigate = useNavigate();

    React.useEffect(() => {
        load(Http.initStorage())
    }, []);

    const load = (params) => {
        Http.get('/user/article', params, res => {
            let temp = res.filter(a => a.cover)
            setList(temp)
        }, e => {
            if (e === 401) {
                if (window.dbbqb) {
                    window.dbbqb.login()
                } else {
                    toast.show('请先登录')
                }
            } else {
                toast.show('出错啦')
            }
        })
    }

    const goto = (id) => {
        navigate('/app/article/' + id + '/detail.html?preview=true');
    }

    const back = () => {
        if (window.dbbqb) {
            window.dbbqb.back()
        }
    }

    return (
        <>
            <Dbbqb.Tool style={{ padding: '0 1rem', justifyContent: 'initial' }}>
                <BackIcon style={{ padding: '1rem 0' }} onClick={back} />
            </Dbbqb.Tool>
            {
                <MasonryInfiniteGrid style={{ position: 'absolute', margin: '1.2rem', top: '2.4rem', bottom: 0, right: 0, left: 0 }}>
                    {
                        list.map(item => (
                            <div key={item.hashId} style={{ width: 'calc(50% - 10px)' }}>
                                {
                                    item.type === 'ARTICLE' ?
                                        <Dbbqb.Cover src={Http.resource(item.cover.path)} onClick={() => goto(item.hashId)} /> :
                                        <Dbbqb.Cover src={Http.path(item.cover)} onClick={() => goto(item.hashId)} />
                                }
                                <Dbbqb.Title style={{ marginBottom: '1rem' }}>{item.title}</Dbbqb.Title>
                            </div>
                        ))
                    }
                </MasonryInfiniteGrid>
            }
        </>
    )
}

export default observer(ArticleList)