
import React, { useImperativeHandle } from "react";
import * as Dbbqb from '../styleds/SegmentImageStyled';
import DeleteIcon from '@mui/icons-material/HighlightOff';
import AddIcon from '@mui/icons-material/Add';
import Http from '../../dbbqb';

const SegmentImage = React.forwardRef((props, ref) => {
    const inputFileEl = React.useRef(null)
    const [images, setImages] = React.useState([null, null, null]);
    const [files, setFiles] = React.useState([null, null, null]);
    const [position, setPosition] = React.useState(-1);

    useImperativeHandle(ref, () => ({
        data: () => {
            let map = {}
            for (let i = 0; i < 3; i++) {
                if (images[i] && files[i]) {
                    map[images[i]] = files[i]
                }
            }
            return map
        }
    }))

    React.useEffect(() => {
        const arr = (props.segment.images || [])
        let temp = []
        for (let i = 0; i < arr.length; i++) {
            temp[i] = Http.resource(arr[i])
        }
        setImages(temp)
    }, [])

    const inputFileChange = () => {
        let file = inputFileEl.current.files[0]
        if (file.size > 3 * 1024 * 1024) {
            inputFileEl.current.value = null
            props.toast.show('图片需小于3MB')
            return
        }
        if (file.size < 10 * 1024) {
            inputFileEl.current.value = null
            props.toast.show('图片需大于10KB')
            return
        }
        inputFileEl.current.value = null
        let path = window.URL.createObjectURL(file)
        images[position] = path
        setImages(e => [...e])
        files[position] = file
        setFiles(e => [...e])
        props.onChange && props.onChange(images)
    }

    const addImage = (pos) => {
        setPosition(pos)
        inputFileEl.current.click()
    }

    const delImage = (pos) => {
        if (pos === 0 && !images[pos]) {
            props.onClose && props.onClose()
        } else {
            images[pos] = null
            setImages(e => [...e])
            files[position] = null
            setFiles(e => [...e])
            props.onChange && props.onChange(images)
        }
    }

    const showImage = (pos) => {
        if (images[pos]) {
            return { backgroundImage: 'url(' + images[pos] + ')', border: '0.1rem solid #fff' }
        }
        if (pos > 0 && !images[pos - 1]) {
            return { display: 'none' }
        }
        return {}
    }

    return (
        <>
            <Dbbqb.Container>{
                [0, 1, 2].map(i => (
                    <Dbbqb.Image key={'image.' + i} style={showImage(i)}>
                        {images[i] ?
                            <DeleteIcon className="del" onClick={() => delImage(i)} /> :
                            <AddIcon onClick={() => addImage(i)} />
                        }
                        {
                            i === 0 && !images[i] &&
                            <DeleteIcon className="del" onClick={() => delImage(i)} />
                        }
                    </Dbbqb.Image>
                ))
            }</Dbbqb.Container>
            <input ref={inputFileEl} style={{ display: 'none' }} type="file" accept="image/*" onChange={inputFileChange} />
        </>
    )
})

export default SegmentImage