import styled from '@emotion/styled';

export const Container = styled.div`
background: white;
position: relative;
height: 100%;
`

export const ArticleUser = styled.div`
display: flex;
height: 4rem;
font-size: 1rem;
align-items: center;
`

export const ArticleUserHeader = styled.div`
width: 2rem;
height: 2rem;
border-radius: 50%;
`

export const Content = styled.div`
overflow: scroll;
padding: 0 1.2rem;
position: absolute;
top: 4rem;
bottom: 3.5rem;
left: 0;
right: 0;
::-webkit-scrollbar { display: none }
`

export const SegmentImage = styled.div`
display: flex;
align-items: center;
justify-content: space-between;
margin-bottom: 0.5rem
`

export const Foot = styled.div`
position: absolute;
display: flex;
height: 3.5rem;
bottom: 0;
left: 0;
right: 0;
padding: 0 1rem;
box-shadow: 0 -0.1rem 0.1rem #f5f5f5;
background: white;
`

export const Input = styled.input`
border: none;
outline: none;
background: #f5f5f5;
border-radius: 1.2rem;
padding: 0.6rem 0.8rem;
z-index: 10;
width: 100%;
`

export const Submit = styled.span`
position: absolute;
right: 1rem;
color: #BAB8CC;
z-index: 20;
`

export const ShareItem = styled.div`
display: flex;
flex-direction: column;
align-items: center;
margin-right: 1rem;
& img {
    width: 3rem;
    height: 3rem;
    box-shadow: 0 0 0.1rem 0 #bbb;
    border-radius: 0.6rem;
}
& span {
    padding-top: 0.5rem;
}

`
export const SubmitButton = styled.button`
color: white;
background: linear-gradient(45deg,#FFBD00,#FF8800);
border: none;
border-radius: 1rem;
padding: 0.3rem 1rem;
`